import BaseComponent from '../BaseComponent';

export default class ScrollHandler extends BaseComponent {
  constructor(root) {
    super(...arguments);

    this.root = root; // document.documentElement,
    this.scrollTop = 0;
    this.isForceScrolling = false;
    this.isScrollingDown = false;
    this._rafId = null;
    this.isPastHero = false;

    this._onScrollHandler = this._onScroll.bind(this);

    this.doc = document.documentElement;
    this.hero = this.doc.querySelector('.TheHero--large');
    if(this.hero) {
      this.heroHeight = this.hero.offsetHeight + this.hero.offsetTop;
    }

    // TODO: This should be maybe be able to be unregistered.
    this.root.addEventListener('scroll', this._onScrollHandler, {
      passive: true
    });
  }

  _onScroll(evt) {
    if(this.isForceScrolling) {
      this.isForceScrolling = false;
    } else {
      const newScrollY = (this.root.scrollTop || this.root.pageYOffset) - (this.root.clientTop || 0);

      if(newScrollY !== this.scrollTop) {
        const lastScroll = this.scrollTop;
        this.scrollTop = Math.max(newScrollY, 0);

        if(this.scrollTop === 0) {
          this.isScrollingDown = false;
          this.emit('top', '');
        } else if (this.scrollTop > lastScroll && !this.isScrollingDown) {
          this.isScrollingDown = true;
          this.emit('change:direction', this.isScrollingDown);
        } else if (this.scrollTop < lastScroll && this.isScrollingDown) {
          this.isScrollingDown = false;
          this.emit('change:direction', this.isScrollingDown);
        }
      }
    }

    //
    // Detect if we have scrolled past hero
    //
    if(this.hero) {
      if (this.isPastHero && this.scrollTop < this.heroHeight) {
        this.isPastHero = false;
        this.emit('pass:hero', {isPast: this.isPastHero});
      } else if (!this.isPastHero && this.scrollTop > this.heroHeight) {
        this.isPastHero = true;
        this.emit('pass:hero', {isPast: this.isPastHero});
      }
    }
  }

  scrollTo(y) {
    if (this.root.scrollTop) {
      this.root.scrollTop = y;
    } else {
      this.root.scroll(0, y);
    }

    this.scrollTop = y;
  }

  goTo(y) {
    if(this._rafId) {
      cancelAnimationFrame(this._rafId);
    }
    const tick = () => {
      // Ease
      let newY = this.scrollTop + Math.floor((y - this.scrollTop) / 8);
      if(newY === this.scrollTop || newY < 0) {
        this.scrollTo(newY);
        this.isForceScrolling = false;
      } else {
        this.isForceScrolling = true;
        this.scrollTo(newY);
        this._rafId = requestAnimationFrame(tick);
      }
    }

    this._rafId = requestAnimationFrame(tick);
  }
}