import { VideoController } from './VideoController';

export default class VideoEmbed {

  constructor(root) {
    // Root elements
    this.root = root;
    this.iframe = root.querySelector('iframe');
    this.playBtn = root.querySelector('.VideoEmbed__play');

    if(!this.root || !this.iframe) {
      return;
    }

    this.controller = new VideoController(this.iframe);
    this.registerEventHandlers();
  }

  registerEventHandlers() {
    if(this.playBtn) {
      this.playClickListener = this.playVideo.bind(this);
      this.playBtn.addEventListener('click', this.playClickListener);
      this.playBtn.addEventListener('mouseover', () => {
        this.root.classList.add('VideoEmbed--hovered');
      });
      this.playBtn.addEventListener('mouseout', () => {
        this.root.classList.remove('VideoEmbed--hovered');
      });
    }
    if(this.pauseBtn) {
      this.pauseClickListener = this.pauseVideo.bind(this);
      this.pauseBtn.addEventListener('click', this.pauseClickListener);
    }
  }

  playVideo() {
    this.root.classList.add('VideoEmbed--play');
    this.controller.play();
  }

  pauseVideo() {
    this.controller.pause();
  }

}
