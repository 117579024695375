import { getCookie, setCookie } from 'tiny-cookie'

const COOKIE_NAME = 'CONSENT_PREFERENCE';

window.dataLayer = window.dataLayer || [];
export function gtag() {
  window.dataLayer.push(arguments);
}

// const defaultCookie = JSON.parse(getCookie(COOKIE_NAME)) || {};

// // Ping default
// gtag('consent', 'default', {
//   security_storage: defaultCookie.security_storage || 'granted',
//   functionality_storage: defaultCookie.functionality_storage || 'granted',
//   personalization_storage: defaultCookie.personalization_storage || 'denied',
//   ad_storage: defaultCookie.ad_storage || 'denied',
//   analytics_storage: defaultCookie.analytics_storage || 'denied',
//   wait_for_update: 500,
//   // region: ['US-CA', 'FI']
// });

export function handleConsentPreferences(event) {
  // Prevent submitting
  event.preventDefault();
  const data = new FormData(event.currentTarget);

  updateConsent({
    personalization_storage: !!data.get('personalization_storage'),
    analytics_storage: !!data.get('analytics_storage'),
    ad_storage: !!data.get('ad_storage'),
  });
}

export function toggleConsent(on) {
  updateConsent({
    personalization_storage: on,
    analytics_storage: on,
    ad_storage: on,
  });
}

export function updateConsent(data = {}) {
  const consents = {
    security_storage: 'granted',
    functionality_storage: 'granted',
    personalization_storage: data.personalization_storage ? 'granted' : 'denied',
    analytics_storage: data.analytics_storage ? 'granted' : 'denied',
    ad_storage: data.ad_storage ? 'granted' : 'denied',
  }

  // Set (functional) cookie
  setCookie(COOKIE_NAME, JSON.stringify(consents), { expires: getTimestamp(1) });

  // Push to datalayer
  gtag('consent', 'update', consents);
}

function getTimestamp(months = 1, now = new Date) {
  now.setMonth(now.getMonth() + months);
  return now.toGMTString();
}
